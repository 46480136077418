header {
    padding: 3rem 0 1rem;
    overflow: hidden;
    height: 100%;
}

.header-container {
    text-align: center;
    height: 100%;
    position: relative;
}

.grid-container {
    display: grid;
}

#get-to-know-container {
    grid-template-columns: 1fr;
    width: var(--container-width-sm);
    margin: 0 auto;
    gap: 2rem;
    align-items: flex-start;
    position: relative;
}

.box {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header-socials > a {
    color: var(--color-bg-variant);
    font-size: 1.5rem;
}

.header-socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: -4rem;
    top: 10rem;
}

.header-socials:after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-bg-variant);
}

.scroll-down {
    position: absolute;
    right: -7rem;
    top: 15rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 1.5rem;
    color: var(--color-bg-variant);
}

/* =========== MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 1025px) {   
    .header__socials,
    .scroll__down {
        display: none;
    }
}
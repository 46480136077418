* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #a0b1bd;
  --color-bg-variant: #1b3143;
  --color-primary: #d7e0dd;
  --color-primary-variant: #526c64;
  --color-white: #ffffff;
  --color-light: #dce4e2;

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family:"Poppins",
    sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/noisebg.jpg);
    /* , linear-gradient(83.21deg, #d5c7bc 0%, #ff4057 100%); */

    background-blend-mode: overlay;
    background-attachment: fixed;

}



/* =========== GENERAL STYLES ============= */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

.full-container {
  width: 100%;
}

h1 {
  font-size: 2.75rem;
  line-height: 1;
  margin-block: 8px;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
  color: var(--color-bg-variant);
}

h6 {
  font-weight: normal;
  font-size: 1rem;
}

section {
  margin-top: 4rem;
}

section > h2, section > h5 {
  text-align: center;
  color: var(--color-white);
}

section > h5 {
  color: var(--color-bg-variant);
  margin-bottom: 2rem;
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: fit-content;
  padding: 1rem 1.5rem;
  display: inline-block;
  color: var(--color-primary);
  background-color: var(--color-bg-variant);
  border: 1px solid var(--color-bg);
  border-radius: 0.4rem;
  cursor: pointer;
  transition: var(--transition);
  font-size: 16px;
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg-variant);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg-variant);
}

img {
  display: block;
  width: 100%;
  object-fit: contain;
}

/* =========== MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1400px) {
  img {
      object-fit: cover;
  }
}

@media screen and (max-width: 1024px) {
  .container {
      width: var(--container-width-md);
  }
}

/* =========== MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 600px) {
  .container {
      width: var(--container-width-sm);
  }
  :root {
      --swiper-navigation-size: 20px;
  }

  section > h5 {
      margin-bottom: 1rem;
  }
}